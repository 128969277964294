import { useContext} from "react";
import LogoHeader from "./logoHeader/LogoHeader";
import Search from "./search/Search";
import Navigation from "./navigation/Navigation";
import { Guarantee } from "../../images/status";
import NavBottom from "./NavBottom";
import { useLocation, useNavigate } from "react-router-dom";
import useMainService from "../../services/MainService";
import { useEffect } from "react";
import { DateTime } from "luxon";
import { setLocalStorageWithExpiry } from "../../services/setLocalStorageWithExpiry";
import { AuthContext } from "../../providers/AuthProvider";
import { useTranslation } from "react-i18next";


const Header = () => {
    return (
        <>
            <View />
            <NavBottom />
        </>
    );
}

// Извлечение `code` для авторизации VK
const getVkCodeFromURL = (location) => {
    const searchParams = new URLSearchParams(location.search); // Получаем параметры из строки поиска
    let code = searchParams.get('code');

    if (!code && location.hash) {
        // Если `code` не найден в строке поиска, пробуем извлечь его из хеша
        const hashParams = new URLSearchParams(location.hash.replace('#', '?')); // Заменяем '#' на '?', чтобы использовать URLSearchParams
        code = hashParams.get('code');
    }

    return code;
};

// Извлечение `code` для авторизации Google
const getGoogleCodeFromWindowLocation = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('code');
};

const View = () => {
    const { tokenWithVk, tokenWithGoogle } = useMainService();
    const { setIsAuth } = useContext(AuthContext);

    const location = useLocation(); // Хук для получения текущего URL
    const navigate = useNavigate();

    // Извлекаем `code` для VK и Google авторизаций
    const vkCode = getVkCodeFromURL(location);
    const googleCode = getGoogleCodeFromWindowLocation();

    const {t} = useTranslation();

    // Логирование для oauth
    // console.log("View component is triggered!")
    // console.log("location is -> ",location)
    // console.log("vkCode is -> ", vkCode);
    // console.log("googleCode is -> ", googleCode);

    useEffect(() => {
        // console.log("useEffect triggered");

        if (vkCode && (location.hash.includes('vk') || location.pathname.includes('vk'))) {
            tokenWithVk(vkCode)
                .then(data => {
                    const expiryDate = DateTime.fromISO(data.expired, { zone: 'utc' }).setZone('local');
                    setLocalStorageWithExpiry('token', data.token, expiryDate.toISO());
                    setIsAuth(true);
                    // Убираем параметры из URL после успешной авторизации
                    window.history.replaceState({}, document.title, "/");
                    navigate("/");
                })
                .catch(e => console.log("Error VK ->", e));
        } else if (googleCode) {
            tokenWithGoogle(googleCode)
                .then(data => {
                    const expiryDate = DateTime.fromISO(data.expired, { zone: 'utc' }).setZone('local');
                    setLocalStorageWithExpiry('token', data.token, expiryDate.toISO());
                    setIsAuth(true);
                    // Убираем параметры из URL после успешной авторизации
                    window.history.replaceState({}, document.title, "/");
                    navigate("/");
                })
                .catch(e => console.log("Error Google ->", e));
        }
    }, [vkCode, googleCode]);

    return (
        <header className="header">
            <div className="header__container">
                <LogoHeader />

                <div className="header__row">
                    <Search extra={"header__search"} />
                    <div className="header__icon">
                        <img src={Guarantee} alt='guarantee'/>
                        <p>{t('guarantee1')}<br />{t('guarantee2')}</p>
                    </div>
                </div>
                <Navigation />
            </div>
        </header>
    )
}

export default Header;