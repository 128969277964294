import React, { useEffect, useMemo, useState } from "react";
import Popup from "../components/popup/Popup";
import {useTranslation} from "react-i18next";

export const PopupContext = React.createContext({ isShow: false, setShow: () => {}, setMessage: () => {} });


export const PopupProvider = ({ children }) => {
    const [isShow, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (isShow) {
            setOpen(true);
            const timer = setTimeout(() => {
                setOpen(false);
                setShow(false);
                setMessage('');
            }, 3000);

            return () => {
                clearTimeout(timer);
            }
        }
    }, [isShow])

    const onClose = () => {
        setOpen(false);
        setShow(false);
        setMessage('');
    }
    
    const popup = useMemo(() => {
        return open ? <Popup text={message || t("not-authorized")} onClose={onClose} />: null;
    }, [open]);

    return (
        <PopupContext.Provider value={{ isShow, setShow, setMessage }} >
            {popup}
            {children}
        </PopupContext.Provider>
    )
}
