import {MirVisa, Visa} from "../../images/payment";


const GetPaymentMethods = ({t, type, services, account, isAccountValid, amount, isAmountValid, setShow, setMessage}) => [
    {
        title: (
            <span>
                <img src={Visa} alt="Visa"/>
            </span>
        ),
        cardOrigin: 'Kz',
        action: async (account, amount) => {
            try {
                if (type === "steam") {
                    // Логика для Steam
                    if (!account || !isAccountValid) {
                        throw new Error(t("steam-login-invalid"));
                    }
                    if (!amount || !isAmountValid) {
                        throw new Error(t("steam-amount-invalid"));
                    }
                    const result = await services.method1(account, amount);
                    if (!result.link_url) throw new Error(t("steam-error"));
                    return result.link_url;
                } else if (type === "payment") {
                    // Логика для Payment
                    const basketId = localStorage.getItem("basketId");
                    if (!basketId) throw new Error(t("basket-id-missing"));
                    const result = await services.method1(basketId);
                    if (!result.link_url) throw new Error(t("payment-text3"));
                    return result.link_url;
                } else if (type === "pubg") {
                    // Логика для Pubg
                    if (!account || !isAccountValid) {
                        throw new Error(t("pubg-uid-invalid"));
                    }
                    if (!amount || !isAmountValid) {
                        throw new Error(t("pubg-amount-invalid"));
                    }
                    const result = await services.method1(account, amount);
                    if (!result.link_url) throw new Error(t("pubg-error"));
                    return result.link_url;
                }
            } catch (error) {
                setMessage(error.message);
                setShow(true);
                throw error;
            }
        }
    },
    {
        title: (
            <span>
                <img src={MirVisa} alt="Mir"/>
            </span>
        ),
        cardOrigin: 'Ru',
        action: async (account, amount) => {
            try {
                if (type === "steam") {
                    // Логика для Steam
                    if (!account || !isAccountValid) {
                        throw new Error(t("steam-login-invalid"));
                    }
                    if (!amount || !isAmountValid) {
                        throw new Error(t("steam-amount-invalid"));
                    }
                    const result = await services.method2(account, amount);
                    if (!result.link_url) throw new Error(t("steam-error"));
                    return result.link_url;
                } else if (type === "payment") {
                    // Логика для Payment
                    const basketId = localStorage.getItem("basketId");
                    if (!basketId) throw new Error(t("basket-id-missing"));
                    const result = await services.method2(basketId);
                    if (!result.link_url) throw new Error(t("payment-text3"));
                    return result.link_url;
                } else if (type === "pubg") {
                    // Логика для Pubg
                    if (!account || !isAccountValid) {
                        throw new Error(t("pubg-uid-invalid"));
                    }
                    if (!amount || !isAmountValid) {
                        throw new Error(t("pubg-amount-invalid"));
                    }
                    const result = await services.method2(account, amount);
                    if (!result.link_url) throw new Error(t("pubg-error"));
                    return result.link_url;
                }
            } catch (error) {
                setMessage(error.message);
                setShow(true);
                throw error;
            }
        },
    },
];

export default GetPaymentMethods;
