import {useContext, useEffect, useMemo, useState} from "react";
import Search from "../components/header/search/Search";
import useMainService from "../services/MainService";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import FilterSort from "../components/filterSort/FilterSort";
import {AuthContext} from "../providers/AuthProvider";
import {useNavigate} from "react-router-dom";

const ReplenishmentHistory = () => {
    const { getTopUpReplenishments } = useMainService()
    const [replenishments, setReplenishments] = useState([]);
    const [sortState, setSortState] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc'); // Направление сортировки (asc или desc)
    const [dateRange, setDateRange] = useState({ from: null, to: null }); // Диапазон дат
    const {t} = useTranslation();
    const { isAuth } = useContext(AuthContext);
    const navigate = useNavigate();

    // Получаем данные из Redux
    const {currencies,activeCurrency} = useSelector(state => state.cart);

    // Мемоизируем вычисления валюты и её символа
    const currencyAmount = useMemo(() => {
        const currencyExists = activeCurrency && currencies[activeCurrency.index];
        return currencyExists ? currencies[activeCurrency.index].amount : 1;
    }, [activeCurrency, currencies]);

    const currencySymbol = useMemo(() => {
        return activeCurrency ? activeCurrency.symbol : '₸';
    }, [activeCurrency]);

    // Проверка авторизации и редирект, если пользователь не авторизован
    useEffect(() => {
        if (!isAuth || localStorage.getItem('token') === null) {
            navigate('/'); // Перенаправление на главную страницу
        }
    }, [isAuth, navigate]);

    // Получаем данные о пополнениях
    useEffect(() => {
        const fetchReplenishments = async () => {
            try {
                const data = await getTopUpReplenishments();
                setReplenishments(data || []); // Устанавливаем пустой массив, если нет данных
            } catch (err) {
                console.error('Error fetching replenishments:', err);
            }
        };
        fetchReplenishments();
    }, []);

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const replenishmentAmount = (amount) => (amount * currencyAmount).toFixed(2);

    // Фильтруем пополнения по дате (если задан диапазон)
    const filteredReplenishments = useMemo(() => {
        return replenishments.filter(replenishment => {
            const replenishmentDate = new Date(replenishment.createdDate);
            const inRange = (!dateRange.from || replenishmentDate >= dateRange.from) &&
                (!dateRange.to || replenishmentDate <= dateRange.to);
            return inRange;
        });
    }, [replenishments, dateRange]);

    // Логика сортировки
    const sortedReplenishments = useMemo(() => {
        let sorted = [...filteredReplenishments];
        if (sortState === 'date') {
            // Сортировка по дате
            sorted.sort((a, b) => sortDirection === 'asc'
                ? new Date(a.createdDate) - new Date(b.createdDate)
                : new Date(b.createdDate) - new Date(a.createdDate));
        } else if (sortState === 'amount') {
            // Сортировка по сумме
            sorted.sort((a, b) => sortDirection === 'asc' ? a.price - b.price : b.price - a.price);
        }
        return sorted;
    }, [filteredReplenishments, sortState, sortDirection]);

    return (
        <main className="main">
            <div className="information">
                <div className="information__row">
                    <div className="title">{t('topup-history')}</div>
                    <div className="flow">
                        <Search extra={"full"} />

                        <div className="filter">
                            <div className="filter__row">
                                <FilterSort
                                    classes={"filter__sort"}
                                    values={[
                                        { value: t("by-date"), state: "date" },
                                        { value: t("by-price"), state: "amount" }
                                    ]}
                                    setSortState={setSortState}
                                    sortDirection={sortDirection}
                                    setSortDirection={setSortDirection}
                                    setDateRange={setDateRange}
                                />
                            </div>
                        </div>

                        <div className="table fifth">
                            <div className="table__head">
                                <div className="table__elem">{t("payment-id")}</div>
                                <div className="table__elem">{t("payment-date")}</div>
                                <div className="table__elem">{t("payment-status")}</div>
                                <div className="table__elem">{t("payment-amount")}</div>
                                <div className="table__elem">{t("payment-account")}</div>
                            </div>
                            {sortedReplenishments.map((replenishment)=> {
                                return (
                                    <div key={replenishment.id} className="table__row">
                                        <div className="table__elem">{replenishment.id}</div>
                                        <div className="table__elem">{formatDate(replenishment.createdDate)}</div>
                                        <div className="table__elem">{replenishment.statusId}</div>
                                        <div className="table__elem">{replenishmentAmount(replenishment.price)} {currencySymbol}</div>
                                        <div className="table__elem">{replenishment.account}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ReplenishmentHistory;