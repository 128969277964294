import "../scss/catalog.scss";
import {useTranslation} from "react-i18next";
import SteamImage from '../images/components/steam-icon.svg';
import SteamInput from "../components/steamInput/SteamInput";
import {useContext, useEffect, useState} from "react";
import useMainService from "../services/MainService";
import PaymentItem from "../components/paymentItem/PaymentItems";
import Button from "../components/button/Button";
import {SteamInfo} from "../images/system";
import CategoriesList from "../components/categoriesList/CategoriesList";
import {RightLow} from "../images/arrows";
import Path from "../components/path/Path";
import GetPaymentMethods from "../components/paymentMethods/GetPaymentMethods";
import CurrencyToggle from "../components/currencyToggle/CurrencyToggle";
import {PopupContext} from "../providers/PopupProvider";


const Steam = () => {
    const {t} = useTranslation();
    const pathValues = ["Главная", "Steam"];
    const {steamTopUp, steamTopUpPlatron} = useMainService();
    const [isAccountValid, setIsAccountValid] = useState(true);
    const [isAmountValid, setIsAmountValid] = useState(true);
    const [account, setAccount] = useState('');
    const [amount, setAmount] = useState('1000');
    const [isLoginInfoVisible, setIsLoginInfoVisible] = useState(false);
    const [current, setCurrent] = useState(-1);
    const [currency, setCurrency] = useState('₸');
    const [exchangeRate, setExchangeRate] = useState(1);
    const { setShow, setMessage } = useContext(PopupContext);

    const validAccountRegExp = /^[a-zA-Z0-9!@#$%^&*_+(),.?":{}|<>]+$/;
    const validAmountRegExp = /^[0-9]+$/;

    // Получение данных о валютных курсах из sessionStorage
    useEffect(() => {
        const currencies = JSON.parse(sessionStorage.getItem('currencies')); // Предполагается, что это курс валют
        if (currencies) {
            const rub = currencies.find(rate => rate.name === 'RUB');
            const kzt = currencies.find(rate => rate.name === 'KZT');
            if (rub && kzt) {
                setExchangeRate(kzt.amount / rub.amount);
            }
        }
    }, []);

    const payments = GetPaymentMethods({
        t,
        type: "steam", // Указываем тип компонента
        services: {
            method1: steamTopUp,
            method2: steamTopUpPlatron,
        },
        account,
        isAccountValid,
        amount,
        isAmountValid,
        setShow,
        setMessage,
    });

    const handleAccountChange = (text) => {
        setAccount(text);
        setIsAccountValid(validAccountRegExp.test(text)); // Сбрасываем ошибку при вводе
    };

    const handleAmountChange = (text) => {
        if (validAmountRegExp.test(text) || text === '') {
            setAmount(text);
            setIsAmountValid(true);
        } else {
            setIsAmountValid(false);
        }
    };

    const handleCurrencyChange = (newCurrency) => {
        if (newCurrency !== currency) {
            if (newCurrency === '₽' && currency === '₸') {
                setAmount(prevAmount => Number((prevAmount / exchangeRate).toFixed(0)));
            } else if (newCurrency === '₸' && currency === '₽') {
                setAmount(prevAmount => Number((prevAmount * exchangeRate).toFixed(0)));
            }
            setCurrency(newCurrency);
        }
    };


    const loginInfoIsActive = (bool) => {
        setIsLoginInfoVisible(bool);
    };

    const toPaymentClick = async () => {
        if (!account || !isAccountValid) {
            setIsAccountValid(false);
            return;
        }

        if (!amount || !isAmountValid) {
            setIsAmountValid(false);
            return;
        }

        let adjustedAmount = amount;
        // Если выбран steamTopUp (cardOrigin === 'Kz') и текущая валюта '₽', пересчитываем сумму в '₸'
        if (payments[current].cardOrigin === 'Kz' && currency === '₽') {
            adjustedAmount = Number((amount * exchangeRate).toFixed(0));
        } else
        if (payments[current].cardOrigin === 'Ru' && currency === '₸') {
            adjustedAmount = Number((amount / exchangeRate).toFixed(0));
        }


        try {
            const resultLink = await payments[current].action(account, adjustedAmount);

            if (resultLink) {
                localStorage.removeItem('basketId');
                window.location.replace(resultLink);  // Редирект на ссылку Kassa24
            } else {
                setMessage('Ссылка на оплату отсутствует. Пожалуйста, попробуйте снова.');
                setShow(true);
            }
        } catch (error) {
            setMessage(error.message || 'Произошла ошибка при попытке получить ссылку на оплату. Пожалуйста, попробуйте снова.');
            setShow(true);
        }
    }

    const handleAmountKeyDown = (e) => {
        if (!/^[0-9]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
            e.preventDefault(); // Отменяем ввод
        }
    };


    return (
        <main className="main">
            <CategoriesList/>
            <Path values={pathValues} icon={RightLow}/>
            <div className="main__container">
                <div className="title">{t('steam-link')}</div>
            </div>
            <div className="steam-container">
                <div className="steam-info-wrapper">
                    <div className="centered-container">
                        <img src={SteamImage} alt="steam"/>
                    </div>
                    <div>
                        <SteamInput
                            theme={
                                <div
                                    onMouseEnter={() => loginInfoIsActive(true)}
                                    onMouseLeave={() => loginInfoIsActive(false)}
                                    className="steam-payment-info"
                                >
                                    {isLoginInfoVisible && (
                                        <div className="steam-info-popup" style={{padding: "0px"}}>
                                            <img src={SteamInfo} alt="steam login info"/>
                                        </div>
                                    )}
                                    {t("steam-login")}❔
                                </div>
                            }
                            check={text => validAccountRegExp.test(text)}
                            setText={handleAccountChange}
                            placeHolder={t("steam-login-placeholder")}
                            extraClass={!isAccountValid ? 'error' : ''}
                            error={!isAccountValid ? t("steam-login-invalid") : ""}
                        />
                    </div>
                    <div className="steam-input-wrapper">
                        <SteamInput
                            theme={t("steam-sum")}
                            check={text => validAmountRegExp.test(text)}
                            setText={handleAmountChange}
                            placeHolder={t("steam-sum-placeholder")}
                            inputType="number"
                            inputMode="numeric"
                            onKeyDown={handleAmountKeyDown}
                            extraClass={!isAmountValid ? 'error' : ''}
                            error={!isAmountValid ? t("steam-amount-invalid") : ""}
                            text={amount}
                        />
                        <CurrencyToggle activeCurrency={currency} onCurrencyChange={handleCurrencyChange}/>
                    </div>


                    <div className="payments-topup">
                        {payments.map((item, index) => (
                            <PaymentItem
                                onClick={() => setCurrent(index)}
                                key={index}
                                src={item.src}
                                title={item.title}
                                text={item.text}
                                isSelected={current === index}
                                isTopup={true}
                                cardOrigin={item.cardOrigin}
                            />
                        ))}
                    </div>

                    <Button
                        onClick={toPaymentClick}
                        text={t("to-payment")}
                        extra={"btn-medium"}
                    />
                </div>

            </div>
        </main>
    );
};

export default Steam;