import React, {useState} from "react";
import {useId} from "react";
import {CheckRoundGreen} from "../../images/status";
import {PaymentMethodNotice} from "../paymentMethodNotice/PaymentMethodNotice";

const PaymentItem = (props) => {
    const id = useId();
    const {src, title, text, onClick, isSelected, isTopup, cardOrigin} = props;
    const [isModalOpen, setModalOpen] = useState(false); // состояние для открытия модального окна

    const handlePaymentClick = () => {
        try {
            onClick();
            console.log("You choice payment method")
        } catch (error) {
            setModalOpen(true);
        }
    };


    return (
        <div style={{display:"flex", }}>
            <div onClick={handlePaymentClick}
                 className={`payments__row ${isSelected ? 'selected' : ''}`}>
                {/*<input type="radio" name="payment" id={id}/>*/}
                <label className={!isTopup?"payment":'payment-topup'} htmlFor={id}>
                    {
                        src ? <div className="payment__icon">
                            <img src={src} alt="bank icon"/>
                        </div> : null
                    }

                    <div className="payment__content">
                        <div className="payment__title">{title}</div>
                        {/*<div className="payment__text">{text}</div>*/}
                    </div>
                    {/*{isSelected && (*/}
                    {/*    <div>*/}
                    {/*        /!*<div className="arrow-content">*!/*/}
                    {/*        /!*    <div className="arrow-icon">*!/*/}
                    {/*        /!*        <img src={CheckRoundGreen} alt="add favourite game"/>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*    </div>*/}
                    {/*)}*/}

                </label>
            </div>
            <div>
                <PaymentMethodNotice cardOrigin={cardOrigin}/>
            </div>
        </div>
    );
}

export default PaymentItem;
