import {Ru} from "../../images/payment";
import {Kz} from "../../images/payment";

const images = {
    Kz,
    Ru,
};

export const PaymentMethodNotice = ({cardOrigin}) => {
    const imageSrc = images[cardOrigin];
    return (
        <div className="topup-notification">
            <img src={imageSrc} alt="card origin"/>
        </div>
    )
}