import List from "../list/List";
import NavbarItem from "../navbarItem/NavbarItem";
import {useContext, useEffect, useState} from "react";
import useMainService from "../../services/MainService";
import {useLocation, useNavigate} from "react-router-dom";
import { HomeIcon, HomeGreen, MoneyIcon, MoneyGreen, CheckIcon, CheckGreen, ArcadeIcon, ArcadeGreen, Steam, Pubg } from '../../images/category/navbar';
import { useTranslation } from "react-i18next";
import {AuthContext} from "../../providers/AuthProvider";
import {PopupContext} from "../../providers/PopupProvider";

const CategoriesList = (props) => {
    const [categories, setCategories] = useState([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const category = searchParams.get('categoryname');
    const { t } = useTranslation();
    const { error, getAllCategories } = useMainService();

    const authContext = useContext(AuthContext);
    const { setShow } = useContext(PopupContext);
    const navigate = useNavigate();

    useEffect(() => {
        getAllCategories()
            .then(data => setCategories(data))
            .catch(log => console.log(log));
    }, [])

    // Обработчик перехода на страницу Steam
    const toSteam = () => {
        if (authContext.isAuth) {
            navigate('/steam');
        } else {
            setShow(true); // Показываем модальное окно авторизации, если пользователь не авторизован
        }
    }

    // Обработчик перехода на страницу Pubg
    const toPubg = () => {
        if (authContext.isAuth) {
            navigate('/pubg');
        } else {
            setShow(true); // Показываем модальное окно авторизации, если пользователь не авторизован
        }
    }

    return (
        <List childClass={"navbar m-left"}>
            <NavbarItem
                to={`/`}
                src={HomeIcon}
                srcHover={HomeGreen}
                alt={"home"}
                text={t('main-link')} />
            <NavbarItem
                to={`/catalog`}
                src={MoneyIcon}
                srcHover={MoneyGreen}
                alt={"game"}
                text={t('catalog-link')} />
            <NavbarItem
                to={`/subscribes`}
                src={CheckIcon}
                srcHover={CheckGreen}
                alt={"subcribes"}
                text={t('subscribes-link')} />
            <NavbarItem
                to={`/categories`}
                src={ArcadeIcon}
                srcHover={ArcadeGreen}
                alt={"categories"}
                text={t('categories-link')} />
            <a className="navbar__item navbar__item-divider">
                <div className="navbar__divider">
                    <span></span>
                </div>
            </a>
            <div onClick={toSteam}>
                <NavbarItem
                    to={authContext.isAuth ? `/steam` : `#`}
                    src={Steam}
                    srcHover={Steam}
                    alt={"steam"}
                    text={t("steam-link")} />
            </div>
            <div onClick={toPubg}>
                <NavbarItem
                    to={authContext.isAuth ? `/pubg` : `#`}
                    src={Pubg}
                    srcHover={Pubg}
                    alt={"pubg"}
                    text={t("pubg-link")} />
            </div>
        </List>
    );
}

export default CategoriesList;