import "../scss/catalog.scss";
import {useTranslation} from "react-i18next";
import PubgImage from '../images/components/pubg.svg';
import SteamInput from "../components/steamInput/SteamInput";
import {useContext, useEffect, useState} from "react";
import useMainService from "../services/MainService";
import Button from "../components/button/Button";
import CategoriesList from "../components/categoriesList/CategoriesList";
import {RightLow} from "../images/arrows";
import Path from "../components/path/Path";
import {PopupContext} from "../providers/PopupProvider";
import Select from "../components/select/Select";
import GetPaymentMethods from "../components/paymentMethods/GetPaymentMethods";
import PaymentItem from "../components/paymentItem/PaymentItems";


const Pubg = () => {
    const {t} = useTranslation();
    const pathValues = ["Главная", "Pubg"];
    const {pubgTopUp, pubgTopUpPlatron, getPubgTopUpList} = useMainService();
    const [isAccountValid, setIsAccountValid] = useState(true);
    const [uid, setUid] = useState('');
    const [selectedAmount, setSelectedAmount] = useState('');
    const [amounts, setAmounts] = useState([]);
    const [current, setCurrent] = useState(-1);

    const { setShow, setMessage } = useContext(PopupContext);

    const validAccountRegExp = /^[0-9]+$/;

    //Получить список доступных пополнений
    useEffect(() => {
        const fetchAmounts = async () => {
            try {
                const data = await getPubgTopUpList();
                if (data && Array.isArray(data)) {
                    // Формируем список для Select
                    const formattedAmounts = data.map(item => ({
                        text: item.name, // Название пакета
                        value: item.id   // ID пакета
                    }));
                    setAmounts(formattedAmounts);
                } else {
                    console.warn('Empty or invalid data received from getPubgTopUpList');
                }
            } catch (error) {
                console.error('Error fetching top-up list:', error);
                setAmounts([]);
            }
        };

        fetchAmounts();
    }, []);

    const payments = GetPaymentMethods({
        t,
        type: "pubg",
        services: {
            method1: pubgTopUp,
            method2: pubgTopUpPlatron,
        },
        account: uid,
        isAccountValid,
        amount: selectedAmount,
        isAmountValid: Boolean(selectedAmount),
        setShow,
        setMessage,
    });

    const handleAccountChange = (text) => {
        setUid(text);
        setIsAccountValid(validAccountRegExp.test(text)); // Сбрасываем ошибку при вводе
    };

    const toPaymentClick = async () => {
        if (current === -1) {
            setMessage(t("pubg-payment-method-invalid"));
            setShow(true);
            return;
        }
        if (!uid || !isAccountValid || !selectedAmount) {
            setIsAccountValid(false);
            return;
        }


        try {
            const resultLink = await payments[current].action(uid, selectedAmount);
            // Логируем ответ от метода
            // console.log('Ответ от pubgTopUp:', result);
            // console.log('Тип result:', typeof result);
            // console.log('Содержимое result:', result);
            // const parsedResult = typeof resultLink === 'string' ? JSON.parse(resultLink) : resultLink;
            // console.log('Преобразованный result:', parsedResult);

            if (resultLink && resultLink.startsWith("http")) {
                // console.log('Переход на ссылку:', parsedResult.link_url);
                // Перенаправляем пользователя на полученную ссылку
                window.location.href = resultLink;
            } else {
                console.log("Pubg error");
                setShow(true);
            }
        } catch (error) {
            console.error('Ошибка в pubgTopUp:', error.message);
            setMessage(error.message || t("steam-error"));
            setShow(true);
        }
    }

    const handleAccountKeyDown = (e) => {
        if (!/^[0-9]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "Ctrl" && e.key !== "v")   {
            e.preventDefault(); // Отменяем ввод
        }
    };

    return (
        <main className="main">
            <CategoriesList/>
            <Path values={pathValues} icon={RightLow}/>
            <div className="main__container">
                <div className="title">{t('pubg-link')}</div>
            </div>
            <div className="pubg-container">
                <div className="steam-info-wrapper">
                    <div className="centered-container">
                        <img src={PubgImage} alt="pubg"/>
                    </div>
                    <div>
                        <SteamInput
                            theme={t("pubg-uid")}
                            check={text => validAccountRegExp.test(text)}
                            setText={handleAccountChange}
                            placeHolder={t("pubg-uid-placeholder")}
                            extraClass={!isAccountValid ? 'error' : ''}
                            error={!isAccountValid ? t("pubg-uid-invalid") : ""}
                            onKeyDown={handleAccountKeyDown}
                        />
                    </div>
                    <div>
                        <Select
                            text={t("pubg-amount-choice")}
                            items={amounts}
                            setAnswer={setSelectedAmount} />
                    </div>

                    <div className="payments-topup">
                        {payments.map((item, index) => (
                            <PaymentItem
                                onClick={() => setCurrent(index)}
                                key={index}
                                src={item.src}
                                title={item.title}
                                text={item.text}
                                isSelected={current === index}
                                isTopup={true}
                                cardOrigin={item.cardOrigin}
                            />
                        ))}
                    </div>

                    <Button
                        onClick={toPaymentClick}
                        text={t("to-payment")}
                        extra={"btn-medium"}
                    />
                </div>

            </div>
        </main>
    );
};

export default Pubg;