import React from 'react';

const CurrencyToggle = ({ activeCurrency, onCurrencyChange }) => {
    const currencies = ['₸', '₽'];

    return (
        <div className="currency-toggle">
            {currencies.map((currency, index) => {
                return (
                    <div
                        key={index}
                        className={`currency-toggle__element ${
                            activeCurrency === currency ? 'active' : ''
                        }`}
                        onClick={() => onCurrencyChange(currency)}
                    >
                        {currency}
                    </div>
                )
            })}
        </div>
    );
};

export default CurrencyToggle;
